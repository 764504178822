import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TCPage = () => {
  return (
    <Layout>
      <div className="vertical-line"></div>
      <SEO title="Terms and Conditions" />
      <h1>Terms and Conditions</h1>
      <section>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mi
          enim, imperdiet ac rutrum eu, pharetra at nisl. Suspendisse elementum
          purus faucibus augue congue, quis laoreet urna pulvinar. Cras et
          ligula porta, faucibus libero maximus, mollis lacus. Vestibulum varius
          et leo vel porta. Maecenas molestie convallis bibendum. Proin sodales
          condimentum ante, nec malesuada orci interdum et. Cras eget lacus sit
          amet tortor congue laoreet in at quam. In sit amet lectus leo.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mi
          enim, imperdiet ac rutrum eu, pharetra at nisl. Suspendisse elementum
          purus faucibus augue congue, quis laoreet urna pulvinar. Cras et
          ligula porta, faucibus libero maximus, mollis lacus. Vestibulum varius
          et leo vel porta. Maecenas molestie convallis bibendum. Proin sodales
          condimentum ante, nec malesuada orci interdum et. Cras eget lacus sit
          amet tortor congue laoreet in at quam. In sit amet lectus leo.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mi
          enim, imperdiet ac rutrum eu, pharetra at nisl. Suspendisse elementum
          purus faucibus augue congue, quis laoreet urna pulvinar. Cras et
          ligula porta, faucibus libero maximus, mollis lacus. Vestibulum varius
          et leo vel porta. Maecenas molestie convallis bibendum. Proin sodales
          condimentum ante, nec malesuada orci interdum et. Cras eget lacus sit
          amet tortor congue laoreet in at quam. In sit amet lectus leo.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam mi
          enim, imperdiet ac rutrum eu, pharetra at nisl. Suspendisse elementum
          purus faucibus augue congue, quis laoreet urna pulvinar. Cras et
          ligula porta, faucibus libero maximus, mollis lacus. Vestibulum varius
          et leo vel porta. Maecenas molestie convallis bibendum. Proin sodales
          condimentum ante, nec malesuada orci interdum et. Cras eget lacus sit
          amet tortor congue laoreet in at quam. In sit amet lectus leo.
        </p>
      </section>
    </Layout>
  )
}

export default TCPage
